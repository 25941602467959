import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import axios from 'axios';

import { Settings } from '../app.config';

export const Contact = () => {
  const elements = Settings.pages.contact;

  const { t } = useTranslation();
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      curious: '',
      interested: [],
      questions: []
    },
    onSubmit: values => {
      const url = "https://us-central1-ctedu-home.cloudfunctions.net/forms-forms";
      let data = values;
      data['form'] = 'contact';
      axios.post(url, values).then(() => alert('submited'));
      // alert(JSON.stringify(values, null, 2));
    },
  });

  return (
    <div className="pt-14">
      <h2 className="text-center font-serif text-2xl text-gray-700 p-6  lg:text-5xl">
        {t(elements.header.title[0])} <br />
        {t(elements.header.title[1])}
      </h2>

      {/* <Block data={elements.blocks.trial} />
      <Block data={elements.blocks.tour} />
      <Block data={elements.blocks.sample} /> */}

      {/* <TrainingPrograms></TrainingPrograms> */}

      {/* <Block data={elements.blocks.advisor} />
      <Block data={elements.blocks.lte} /> */}

      <div className="flex items-top justify-center bg-white dark:bg-gray-900 sm:items-center sm:pt-0 pb-6">
        
        <div className="max-w-6xl mx-auto sm:px-6 lg:px-8">
          <div className="mt-8 overflow-hidden">
            <div className="grid grid-cols-3 grid-rows-flow">
              <div className="p-6 col-span-1 mr-2 bg-blue-lightlight dark:bg-gray-800 sm:rounded-lg">
                {/* <h1 className="text-4xl sm:text-5xl text-gray-800 dark:text-white font-extrabold tracking-tight">
                  Get in touch
                </h1> */}
                <p className="text-normal text-lg sm:text-2xl font-medium text-gray-600 dark:text-gray-400 mt-2">
                  Fill in the htmlForm to start a conversation
                </p>

                <div className="flex items-center mt-8 text-gray-600 dark:text-gray-400">
                  <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" viewBox="0 0 24 24" className="w-8 h-8 text-gray-500">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"/>
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"/>
                  </svg>
                  <div className="ml-4 text-md tracking-wide font-semibold w-40">
                    Acme Inc, Street, State,
                    Postal Code
                  </div>
                </div>

                <div className="flex items-center mt-4 text-gray-600 dark:text-gray-400">
                  <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" viewBox="0 0 24 24" className="w-8 h-8 text-gray-500">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"/>
                  </svg>
                  <div className="ml-4 text-md tracking-wide font-semibold w-40">
                    +44 1234567890
                  </div>
                </div>

                <div className="flex items-center mt-2 text-gray-600 dark:text-gray-400">
                  <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" viewBox="0 0 24 24" className="w-8 h-8 text-gray-500">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"/>
                  </svg>
                  <div className="ml-4 text-md tracking-wide font-semibold w-40">
                    info@acme.org
                  </div>
                </div>
              </div>

              <div className="p-6 col-span-2 flex flex-col justify-center">
                <div>
                  <form onSubmit={formik.handleSubmit}>
                    <div className="flex -mx-3">
                        <div className="w-1/2 px-3 mb-5">
                            <label htmlFor="" className="text-xs font-semibold px-1">First name</label>
                            <div className="flex">
                                <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"><i className="mdi mdi-account-outline text-gray-400 text-lg"></i></div>
                                <input 
                                  type="text" 
                                  className="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-orange" 
                                  placeholder="John"
                                  id="firstName" 
                                  onChange={formik.handleChange}
                                  value={formik.values.firstName}
                               />
                            </div>
                        </div>
                        <div className="w-1/2 px-3 mb-5">
                            <label htmlFor="" className="text-xs font-semibold px-1">Last name</label>
                            <div className="flex">
                                <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"><i className="mdi mdi-account-outline text-gray-400 text-lg"></i></div>
                                <input 
                                  type="text" 
                                  className="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-orange" 
                                  placeholder="Smith" 
                                  id="lastName"
                                  onChange={formik.handleChange}
                                  value={formik.values.lastName}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex -mx-3">
                        <div className="w-1/2 px-3 mb-5">
                            <label htmlFor="" className="text-xs font-semibold px-1">Email</label>
                            <div className="flex">
                                <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"><i className="mdi mdi-email-outline text-gray-400 text-lg"></i></div>
                                <input 
                                  type="email" 
                                  className="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-orange" 
                                  placeholder="johnsmith@example.com" 
                                  id="email"
                                  onChange={formik.handleChange}
                                  value={formik.values.email}
                                />
                            </div>
                        </div>
                        <div className="w-1/2 px-3 mb-5">
                            <label htmlFor="" className="text-xs font-semibold px-1">Phone</label>
                            <div className="flex just">
                                <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"><i className="mdi mdi-email-outline text-gray-400 text-lg"></i></div>
                                <input 
                                  type="phone" 
                                  className="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-orange" 
                                  placeholder="(555) 555 5555" 
                                  id="phone"
                                  onChange={formik.handleChange}
                                  value={formik.values.phone}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="flex -mx-3">
                      <div className="w-full px-3 mb-12">
                        <label htmlFor="" className="text-xs font-semibold px-1">What are you most curipus about?</label>
                        <div className="flex">
                          <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"><i className="mdi mdi-lock-outline text-gray-400 text-lg"></i></div>
                          <textarea 
                            className="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-orange" 
                            placeholder="Your text goes here..." 
                            id="curious"
                            onChange={formik.handleChange}
                            value={formik.values.curious}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="flex -mx-3">
                      <div className="w-1/2 px-3 mb-12">
                        <label htmlFor="" className="text-xs font-semibold px-1">In which program(s) are you interested?</label>
                        <div className="flex">
                          {/* <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"><i className="mdi mdi-lock-outline text-gray-400 text-lg"></i></div> */}
                          <select multiple="multiple" size="5"
                            className="w-full pl-3 pr-3 py-2 overflow-y-hidden rounded-lg border-2 border-gray-200 outline-none focus:border-orange"
                            id="interested"
                            onChange={formik.handleChange}
                            value={formik.values.interested}
                          >
                            <option value="Academic Life Coaching">Academic Life Coaching</option>
                            <option value="University Academic Life Coaching">University Academic Life Coaching</option>
                            <option value="Executive Coaching">Executive Coaching</option>
                            <option value="Wellness Life Coaching">Wellness Life Coaching</option>
                          </select>
                        </div>
                      </div>
                      
                      <div className="w-1/2 px-3 mb-12">
                        <label htmlFor="" className="text-xs font-semibold px-1">In what areas do you have questions?</label>
                        <div className="flex">
                          {/* <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"><i className="mdi mdi-lock-outline text-gray-400 text-lg"></i></div> */}
                          <select multiple="multiple" size="5"
                            className="w-full pl-3 pr-3 py-2 overflow-y-hidden rounded-lg border-2 border-gray-200 outline-none focus:border-orange0"
                            id="questions"
                            onChange={formik.handleChange}
                            value={formik.values.questions}
                          >
                              <option value="Tuition/Packages">Tuition/Packages</option>
                              <option value="Course Schedule">Course Schedule</option>
                              <option value="Program Specializations">Program Specializations</option>
                              <option value="Certification &amp; ICF Credentialing">Certification &amp; ICF Credentialing</option>
                              <option value="Program Logistics (length, frequency, topics, etc.)">Program Logistics (length, frequency, topics, etc.)</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    
                    <div className="flex -mx-3">
                        <div className="w-full px-3 mb-5">
                            <button
                              type="submit" 
                              className="block w-full max-w-xs mx-auto bg-orange hover:bg-orange-dark focus:bg-indigo-700 text-white rounded-lg px-3 py-3 font-semibold">REGISTER NOW</button>
                        </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> 
  );
};