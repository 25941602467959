import React, { useEffect, useState } from "react";

const loadDynamicScript = (callback) => {
  const existingScript = document.getElementById('interact');

  if (!existingScript) {
    const script = document.createElement('script');
    script.src = 'https://i.tryinteract.com/embed/app.js';
    script.id = 'interact'
    document.body.appendChild(script);

    script.onload = () => {
      if (callback) callback();
    };
  }

  if (existingScript && callback) callback();
};

export const Interact = () => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    loadDynamicScript(() => {
        let app_605dff6c464aba00179b2aa1;
        const options = {
            "appId":"605dff6c464aba00179b2aa1",
            "width":"800",
            "height":"800",
            "async":true,
            "host":"quiz.tryinteract.com", 
            "footer":"show"
        };
        app_605dff6c464aba00179b2aa1 = new window.InteractApp();
        app_605dff6c464aba00179b2aa1.initialize(options);
        app_605dff6c464aba00179b2aa1.display();
        setLoaded(true);
    });
  });

  return (
    <div id='interact-605dff6c464aba00179b2aa1'>
        {loaded ? '':''}
    </div>
  );
};

export default Interact;