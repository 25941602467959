import React from "react";
import Interact from "../Interact";
import LazyLoad from "react-lazyload";
import { Settings } from "../../../app.config";
import { useTranslation } from "react-i18next";

export const Quiz = () => {
  const elements = Settings.pages.home;
  const { t } = useTranslation();
  return (
    <div className="flex flex-col items-center py-10">
      <img
        alt="png"
        src="https://www.coachtrainingedu.com/wp-content/uploads/elementor/thumbs/image-6-p4fifghumopji9e5ogzvy31culbmgftzq0dk7ffg0w.png?189db0&189db0"
      />
      <div className=" mx-auto w-3/5  text-center my-8 ">
        <h1 className=" mx-10 text-4xl font-serif text-gray-900">
          {t(elements.block.quiz.title)}
        </h1>
        <h1 className="mx-10 text-2xl text-orange-title  ">
          {t(elements.block.quiz.subtitle)}
        </h1>
      </div>
      <div className="flex mx-40 my-10 ">
        <div className="w-1/2 px-5">
          <h2 className="pb-5 text-lg">{t(elements.block.quiz.explanation)}</h2>
          {elements.block.quiz.items.map((el, index) => {
            return (
              <div key={index} className="py-2">
                {" "}
                ✓ {t(el)}
              </div>
            );
          })}
        </div>
        <div className="w-1/2">
          <LazyLoad offset={100}>
            <Interact />
          </LazyLoad>
        </div>
      </div>
    </div>
  );
};
