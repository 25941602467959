import { Route, Switch } from "react-router-dom";

import { FontAwesome } from "./components/Content/AppContent/FontAwesome";

import { Footer } from "./components/Content/AppContent/Footer";
import { Navbar } from "./components/Content/AppContent/Navbar/NavBar";

import { Home } from "./pages/Home";

import { Training } from "./pages/Training";
import { Certification } from './pages/Certification';
import { Pricing } from "./pages/Pricing";
import { FAQs } from './pages/FAQs';
import { Schedule } from './pages/Schedule';
import { Advisor } from './pages/Advisor';

import { About } from "./pages/About";
import { Diversity } from "./pages/Diversity";
import { Learning } from './pages/Learning';
import { Contact } from "./pages/Contact";

import { GradStories } from './pages/GradStories'
import { Academic } from "./pages/Academic";

import "./App.css";

function App() {
  return (
    <div>
      <Navbar />
      <Switch>
        {/* HOME   */}
        <Route path="/" exact component={Home}></Route>

        {/* BECOME A COACH MENU */}
        <Route path="/training" exact component={Training}></Route>
        <Route path="/certification" exact component={Certification}></Route>
        <Route path="/pricing" exact component={Pricing}></Route>
        <Route path="/faqs" exact component={FAQs}></Route>
        <Route path="/schedule" exact component={Schedule}></Route>
        <Route path="/advisor" exact component={Advisor}></Route>

        {/* ABOUT MENU */}
        <Route path="/about" exact component={About}></Route>
        <Route path="/diversity" exact component={Diversity}></Route>
        <Route path="/philosophy" exact component={Learning}></Route>
        <Route path="/contact" exact component={Contact}></Route>
        
        {/* OTHER IN MENU */}
        <Route path="/grad-stories" exact component={GradStories}></Route>
                
        {/* OTHER PAGES         */}
        <Route path="/academic-life-coaching" exact component={Academic}></Route>

        {/* EXTERNAL LINKS */}
        <Route
          exact
          path="/blog"
          component={() => {
            window.location = "https://campus.coachtrainingedu.com/blog";
            return null;
          }}
        />
        <Route
          exact
          path="/login"
          component={() => {
            window.location = "https://campus.coachtrainingedu.com/";
            return null;
          }}
        />
        <Route
          exact
          path="/apply"
          render={() => {
            window.location = "https://campus.coachtrainingedu.com/apply";
            return null;
          }}
        />

      </Switch>
      <Footer />
      <FontAwesome />
    </div>
  );
}

export default App;
