import React, { useEffect, useState } from "react";

const loadDynamicScript = (callback) => {
  const existingScript = document.getElementById("swarmify");

  if (!existingScript) {
    const script = document.createElement("script");
    script.src = "https://assets.swarmcdn.com/cross/swarmdetect.js";
    script.id = "swarmify";
    document.body.appendChild(script);

    script.onload = () => {
      if (callback) callback();
    };
  }

  if (existingScript && callback) callback();
};

export const Video = ({ videos }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    loadDynamicScript(() => {
      setLoaded(true);
    });
  });

  return (
    <div id="videos">
      {loaded ? (
        <div className="flex justify-center gap-4 py-20">
          {videos.map((video, index) => {
            return (
              <div>
                <smartvideo src={video} controls></smartvideo>
              </div>
            );
          })}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Video;
