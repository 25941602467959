import React from "react";
import TeamProfile from "../components/Content/AboutContent/TeamProfile";
import { useTranslation } from "react-i18next";
import { Settings } from "../app.config";

export const About = () => {
  const elements = Settings.pages.about;
  const { t } = useTranslation();
  const profile = elements.team.map((el) => {
    for (let property in el) {
      el[property] = t(el[property]);
    }
    return el;
  });

  return (
    <div>
      <div className="container mx-auto w-3/4 my-20 grid grid-cols-3">
        <div className="col-span-2">
          <h2 className=" text-center text-6xl font-serif text-gray-700  py-10 ">
            {t(elements.header.title)}
          </h2>
          <h4 className="text-center text-3xl px-4 font-serif text-gray-700  py-10 ">
            {t(elements.header.subtitles[0])}
          </h4>
          <h4 className="text-center text-3xl px-4 font-serif text-gray-700  py-10  ">
            {t(elements.header.subtitles[1])}
          </h4>
        </div>
        <div>
          <img
            src="https://www.coachtrainingedu.com/wp-content/uploads/2020/12/Group-1-3.png"
            alt="world"
          ></img>
        </div>
      </div>

      <div className="container mx-auto w-3/5">
        <h4 className="text-center text-5xl font-serif text-gray-700 py-10 ">
          {t(elements.story.title)}
        </h4>
        <div>
          <p className="my-8">{t(elements.story.text[0])}</p>
          <p className="my-8">{t(elements.story.text[1])}</p>
          <p className="my-8">{t(elements.story.text[2])}</p>
          <p className="my-8">{t(elements.story.text[3])}</p>
          <p className="my-8">{t(elements.story.text[4])}</p>
          <p className="my-8">{t(elements.story.text[5])}</p>
          <p className="my-8">{t(elements.story.text[6])}</p>
        </div>
      </div>

      <div className="container mx-auto w-4/5">
        <h4 className="text-center text-5xl font-serif text-gray-700 py-10 ">
          {t(elements.teamTitle)}
        </h4>
        <div className="grid grid-cols-3">
          {profile.map((el, index) => {
            return (
              <TeamProfile
                key={index}
                image={el.image}
                name={el.name}
                description={el.description}
              ></TeamProfile>
            );
          })}
        </div>
      </div>
    </div>
  );
};

