import React from "react";
import { useTranslation } from "react-i18next";
import { Settings } from "../../../app.config";

export const Content = () => {
  const elements = Settings.pages.home;
  const { t } = useTranslation();

  return (
    <div className="flex justify-center mb-10">
      <img
        className="relative"
        alt="img"
        src="https://www.coachtrainingedu.com/wp-content/uploads/2021/03/life-coach-training-career-office.jpg"
      />
      <h2 className="text-center font-bold text-2xl text-gray-800 font-serif p-14 w-2/5 bg-blue-200 absolute bottom-0 right-0 mb-10 mr-10">
        {t(elements.banner.title[0])} <br />
        {t(elements.banner.title[1])}
      </h2>
    </div>
  );
};
