import React from 'react';
import { useTranslation } from 'react-i18next';
import { Settings } from '../app.config';

import { Header } from '../components/commons/Header';

export const FAQs = () => {
  const elements = Settings.pages.faq;

  const { t } = useTranslation();
  
  return (
    <div>
        <Header data={elements}></Header>
        <div className="p-10">
          <p>
            {t(elements.header.explanation)}
          </p> 
        </div>
    </div> 
  );
};