import React from "react";
import { useTranslation } from "react-i18next";

export const Header = ({ data }) => {
  const { t } = useTranslation();
  return (
    <div className="pt-14">
      <div className="flex justify-center">
        {data.header.img && (
          <img alt={data.header.img.alt} src={data.header.img.url}></img>
        )}
      </div>
      <h2 className="text-center font-serif text-2xl text-gray-700 p-6  lg:text-5xl">
        {t(data.header.title[0])} 
        {data.header.title.length > 1 && (
          <>
            <br /> {t(data.header.title[1])}
          </>
        )}

        {data.header.title.length > 2 && (
          <>
            <br /> {t(data.header.title[2])}
          </>
        )}
      </h2>
      <div className="flex justify-center">
        <h3 className="text-center p-1 max-w-lg text-2xl font-highlight  text-highlight">
          {t(data.header.explanation)}
        </h3>
      </div>
    </div>
  );
};
