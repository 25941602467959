import React from "react";
import { useTranslation } from "react-i18next";

export const Block = ({ data }) => {
  const { t } = useTranslation();

  return (
    <div className=" container mx-auto w-3/5  text-center my-8 lg:my-20  ">
      {data.title.length > 0 && (
        <h1 className=" mx-10 text-4xl font-serif text-gray-900">
          {t(data.title[0])}
          {data.title.length > 1 && (
            <>
              <br /> {t(data.title[1])}
            </>
          )}
        </h1>
      )}
      {data.subtitle.length > 0 && (
        <h2 className="mx-10 text-2xl font-highlight text-highlight">
          {t(data.subtitle[0])}
          {data.subtitle.length > 1 && (
            <>
              <br /> {t(data.subtitle[1])}
            </>
          )}
        </h2>
      )}
      <h2 className="my-10 mx-10 text-2l text-gray-900">
        {t(data.explanation)}
      </h2>

      <div className="grid grid-cols-2 gap-6 mx-40 text-gray-900">
        {data.items.map((el, index) => {
          return <div key={index}> ✓ {t(el)}</div>;
        })}
      </div>
    </div>
  );
};
