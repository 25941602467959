import React from "react";

export const Footer = () => {
  return (
   
      <footer>
      <div className="py-10 bg-blue-50">
      <div className="flex justify-center items-center ">
        <div className="flex flex-col text-center ">
          <div className="flex justify-center items-center ">
            <img
              className="w-24 "
              src="https://www.coachtrainingedu.com/wp-content/uploads/2020/09/edu-logo-5-150x150-1.png?189db0&189db0"
              alt="logo"
            />
          </div>
          <h4 className="text-3xl py-5 font-serif  text-gray-700">Mission</h4>
          <p className="text-sm text-gray-500">
            To provide life coach training that changes lives, launches careers,
            and promotes human flourishing.
          </p>
          <h4 className="text-3xl py-5 font-serif  text-gray-700">
            Let's stay connected
          </h4>
          <form className="flex">
            <input
              className="px-3 py-2 m-2 rounded-md w-1/2"
              type="text"
              placeholder="Name"
            ></input>
            <input
              className="px-3 py-2 m-2 rounded-md w-1/2"
              type="email"
              placeholder="Email"
            ></input>
          </form>
          <button
            type="submit"
            className="bg-green-900 text-white rounded-full font-light font-mono py-2 m-2"
          >
            GET WEEKLY UPDATES
          </button>
          <h4 className="text-3xl pt-5 font-serif  text-gray-700">
            And be social
          </h4>
          <div className="flex justify-center my-10">
            <div>
              <a
                className="m-3 p-2 text-green-900 hover:text-green-700 transition ease-in text-3xl"
                href="https://www.facebook.com/coachtrainingedu"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-facebook"></i>
              </a>
            </div>
            <div>
              <a
                className="m-3 p-2 text-green-900 hover:text-green-700 transition ease-in text-3xl"
                href="https://twitter.com/coachtrainedu?s=20"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-twitter"></i>
              </a>
            </div>
            <div>
              <a
                className="m-3 p-2 text-green-900 hover:text-green-700 transition ease-in text-3xl"
                href="https://www.youtube.com/channel/UC_LOLtWJToDntdbC_he5cXA"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-youtube"></i>
              </a>
            </div>
            <div>
              <a
                className="m-3 p-2 text-green-900 hover:text-green-700 transition ease-in text-3xl"
                href="https://www.instagram.com/coachtrainingedu/"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-instagram"></i>
              </a>
            </div>
            <div>
              <a
                className="m-3 p-2 text-green-900 hover:text-green-700 transition ease-in text-3xl"
                href="https://www.linkedin.com/school/coach-train-edu/"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-linkedin"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center px-10">
        <div className="text-gray-700 w-1/5 flex flex-col items-center text-left">
          <h3 className="my-4 text-gray-800">Key pages</h3>
          <div className="text-sm flex flex-col">
            <a href="/" className="p-1">
              Training
            </a>
            <a href="/" className="p-1">
              Certification
            </a>
            <a href="/" className="p-1">
              Pricing
            </a>
            <a href="/" className="p-1">
              Schedule
            </a>
            <a href="/" className="p-1">
              Apply
            </a>
          </div>
        </div>
        <div className="text-gray-700 w-1/5 flex flex-col items-center text-left">
          <h3 className="my-4 text-gray-800">About</h3>
          <div className="text-sm flex flex-col items-center">
            <a href="/" className="p-1">
              Meet the Trainers
            </a>
            <a href="/" className="p-1">
              Meet the Founders
            </a>
            <a href="/" className="p-1">
              Interview Series
            </a>
            <a href="/" className="p-1">
              Book Club
            </a>
            <a href="/" className="p-1">
              Future Self Party
            </a>
          </div>
        </div>
        <div className="text-gray-700 w-1/5 flex flex-col items-center text-left">
          <h3 className="my-4 text-gray-800">New? Start here</h3>
          <div className="text-sm flex flex-col items-center">
            <a href="/" className="p-1">
              15 Questions to Ask
            </a>
            <a href="/" className="p-1">
              15 Questions Answered
            </a>
            <a href="/" className="p-1">
              Live Training Experience
            </a>
            <a href="/" className="p-1">
              Schedule a Call
            </a>
            <a href="/" className="p-1">
              Blog
            </a>
          </div>
        </div>
        <div className="text-gray-700 w-1/5 flex flex-col items-center text-left">
          <h3 className="my-4 text-gray-800">Logistics</h3>
          <div className="text-sm flex flex-col items-center">
            <a href="/" className="p-1">
              Learning Philosophy
            </a>
            <a href="/" className="p-1">
              Student Materials
            </a>
            <a href="/" className="p-1">
              Privacy Policy
            </a>
            <a href="/" className="p-1">
              Site Map
            </a>
            <a href="/" className="p-1">
              Student Policies
            </a>
          </div>
        </div>
        <div className="text-gray-700 w-1/5 flex flex-col items-center text-left">
          <h3 className="my-4 text-gray-800">Contact</h3>
          <div className="text-sm flex flex-col items-center">
            <a className="p-1" href="/">
              <i className="fas fa-phone mx-1"></i>
              503.360.6700
            </a>
            <a className="p-1" href="/">
              <i className="fas fa-envelope mx-1"></i>
              contact@coachtrainingedu.com
            </a>
            <a className="p-1" href="/">
              <i className="fas fa-map-marker-alt mx-1"></i>
              World Trade Center 121 SW Salmon St. #1100 Portland, Oregon 97204
            </a>
          </div>
        </div>
      </div>
    </div>
      
      
      </footer>
  );
};
