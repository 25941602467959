import React from "react";
import loadable from "@loadable/component";

import { Block } from "../components/commons/Block";
import { Header } from "../components/commons/Header";
import TrainingPrograms from "../components/Content/TrainingContent/TrainingPrograms";

import { Settings } from "../app.config";

const Video = loadable(() => import("../components/commons/Video"));


export const Training = () => {
  const elements = Settings.pages.training;

  return (
    <div>
      <Header data={elements}></Header>

      <Video videos={elements.videos} />

      <Block data={elements.blocks.trial} />
      <Block data={elements.blocks.tour} />
      <Block data={elements.blocks.sample} />

      <TrainingPrograms></TrainingPrograms>

      <Block data={elements.blocks.advisor} />
      <Block data={elements.blocks.lte} />

      <h2 className="container mx-auto  text-center mb-10  text-2l text-gray-700">
        To attend sign up for the free trial and schedule a call with an
        admissions advisor.
      </h2>
    </div>
  );
};