import React from "react";

const TeamProfile = ({ image, name, description }) => {
  return (
    <div className="flex-col mx-10 my-10">
      <div className="w-auto">
        <img className="rounded-full" src={image} alt="profileImg"></img>
      </div>
      <p className="py-10">
        {" "}
        <em>{name} </em>
      </p>
      <p>{description}</p>
    </div>
  );
};

export default TeamProfile;
