module.exports.Settings = {
  languages: [
    {
      id: "en",
      title: "nav-footer:en",
    },
    {
      id: "es",
      title: "nav-footer:es",
    },
  ],
  menu: {
    left: [
      {
        id: "become-a-coach",
        title: "nav-footer:become-a-coach",
        type: "Dropdown",
        subtype: "default",
        path: "/training",
        items: [
          {
            id: "training",
            title: "nav-footer:training",
            type: "Link",
            path: "/training",
          },
          {
            id: "certification",
            title: "nav-footer:certification",
            type: "Link",
            path: "/certification",
          },
          {
            id: "pricing",
            title: "nav-footer:pricing",
            type: "Link",
            path: "/Pricing",
          },
          {
            id: "faqs",
            title: "nav-footer:faqs",
            type: "Link",
            path: "/FAQs",
          },
          {
            id: "schedule",
            title: "nav-footer:schedule",
            type: "Link",
            path: "/Schedule",
          },
          {
            id: "advisor",
            title: "nav-footer:advisor",
            type: "Link",
            path: "/Advisor",
          },
        ],
      },
      {
        id: "about",
        title: "nav-footer:about",
        type: "Dropdown",
        subtype: "default",
        path: "/about",
        items: [
          {
            id: "about-ctedu",
            title: "nav-footer:about-ctedu",
            type: "Link",
            path: "/about",
          },
          {
            id: "diversity",
            title: "nav-footer:diversity",
            type: "Link",
            path: "/Diversity",
          },
          {
            id: "philosophy",
            title: "nav-footer:philosophy",
            type: "Link",
            path: "/Philosophy",
          },
          {
            id: "contact",
            title: "nav-footer:contact",
            type: "Link",
            path: "/Contact",
          },
        ],
      },
      {
        id: "blog",
        title: "nav-footer:blog",
        type: "Link",
        path: "/blog",
      },
      {
        id: "grad-stories",
        title: "nav-footer:grad-stories",
        type: "Link",
        path: "/Grad-stories",
      },
    ],
    right: [
      {
        id: "language-switcher",
        title: "icon",
        type: "Dropdown",
        subtype: "language",
      },
      {
        id: "contact-admissions",
        title: "nav-footer:contact-admissions",
        type: "Link",
        path: "/Contact",
      },
      {
        id: "login",
        title: "nav-footer:login",
        type: "Link",
        path: "/login",
      },
      {
        id: "apply",
        title: "nav-footer:apply",
        type: "Button",
        path: "/apply",
      },
    ],
  },
  pages: {
    about: {
      header: {
        title: "about:header:title",
        subtitles: [
          "about:header:subtitles:subtitle1",
          "about:header:subtitles:subtitle2",
        ],
      },
      story: {
        title: "about:story:title",
        text: [
          "about:story:text:p1",
          "about:story:text:p2",
          "about:story:text:p3",
          "about:story:text:p4",
          "about:story:text:p5",
          "about:story:text:p6",
          "about:story:text:p7",
        ],
      },
      teamTitle: "about:teamTitle",
      team: [
        {
          image: "about:team:t1:image",
          name: "about:team:t1:name",
          description: "about:team:t1:description",
        },
        {
          image: "about:team:t2:image",
          name: "about:team:t2:name",
          description: "about:team:t2:description",
        },
        {
          image: "about:team:t3:image",
          name: "about:team:t3:name",
          description: "about:team:t3:description",
        },
        {
          image: "about:team:t4:image",
          name: "about:team:t4:name",
          description: "about:team:t4:description",
        },
        {
          image: "about:team:t5:image",
          name: "about:team:t5:name",
          description: "about:team:t5:description",
        },
        {
          image: "about:team:t6:image",
          name: "about:team:t6:name",
          description: "about:team:t6:description",
        },
        {
          image: "about:team:t7:image",
          name: "about:team:t7:name",
          description: "about:team:t7:description",
        },
        {
          image: "about:team:t8:image",
          name: "about:team:t8:name",
          description: "about:team:t8:description",
        },
        {
          image: "about:team:t9:image",
          name: "about:team:t9:name",
          description: "about:team:t9:description",
        },
        {
          image: "about:team:t10:image",
          name: "about:team:t10:name",
          description: "about:team:t10:description",
        },
        {
          image: "about:team:t11:image",
          name: "about:team:t11:name",
          description: "about:team:t11:description",
        },
        {
          image: "about:team:t12:image",
          name: "about:team:t12:name",
          description: "about:team:t12:description",
        },
        {
          image: "about:team:t13:image",
          name: "about:team:t13:name",
          description: "about:team:t13:description",
        },
        {
          image: "about:team:t14:image",
          name: "about:team:t14:name",
          description: "about:team:t14:description",
        },
        {
          image: "about:team:t15:image",
          name: "about:team:t15:name",
          description: "about:team:t15:description",
        },
        {
          image: "about:team:t16:image",
          name: "about:team:t16:name",
          description: "about:team:t16:description",
        },
        {
          image: "about:team:t17:image",
          name: "about:team:t17:name",
          description: "about:team:t17:description",
        },
        {
          image: "about:team:t18:image",
          name: "about:team:t18:name",
          description: "about:team:t18:description",
        },
      ],
    },
    training: {
      header: {
        title: ["training:header:line1", "training:header:line2"],
        explanation: "training:header:explanation",
        img: {
          alt: "world",
          url:
            "https://www.coachtrainingedu.com/wp-content/uploads/elementor/thumbs/image-7-p4flwiws703ic0nsjbvm8i9zms72gjpz6lxpgzmh0w.png?189db0&189db0",
        },
      },
      videos: [
        "https://youtu.be/gcC4hG_SmTU",
        "https://youtu.be/0rYFc0Pmb64",
        "https://youtu.be/Jw5AKlKT1UE",
      ],
      blocks: {
        trial: {
          icon: "",
          title: [],
          subtitle: [
            "training:blocks:trial:subtitle:line1",
            "training:blocks:trial:subtitle:line2",
          ],
          explanation: "training:blocks:trial:explanation",
          items: [
            "training:blocks:trial:item1",
            "training:blocks:trial:item2",
            "training:blocks:trial:item3",
            "training:blocks:trial:item4",
          ],
        },
        tour: {
          icon: "",
          title: ["training:blocks:tour:title:line1"],
          subtitle: ["training:blocks:tour:subtitle:line1"],
          explanation: "training:blocks:tour:explanation",
          items: [
            "training:blocks:tour:item1",
            "training:blocks:tour:item2",
            "training:blocks:tour:item3",
            "training:blocks:tour:item4",
          ],
        },
        sample: {
          icon: "",
          title: [
            "training:blocks:sample:title:line1",
            "training:blocks:sample:title:line2",
          ],
          subtitle: ["training:blocks:sample:subtitle:line1"],
          explanation: "training:blocks:sample:explanation",
          items: [
            "training:blocks:sample:item1",
            "training:blocks:sample:item2",
            "training:blocks:sample:item3",
            "training:blocks:sample:item4",
          ],
        },
        advisor: {
          icon: "",
          title: [
            "training:blocks:advisor:title:line1",
            "training:blocks:advisor:title:line2",
          ],
          subtitle: ["training:blocks:advisor:subtitle:line1"],
          explanation: "training:blocks:advisor:explanation",
          items: [
            "training:blocks:advisor:item1",
            "training:blocks:advisor:item2",
            "training:blocks:advisor:item3",
            "training:blocks:advisor:item4",
          ],
        },
        lte: {
          icon: "",
          title: [
            "training:blocks:lte:title:line1",
            "training:blocks:lte:title:line2",
          ],
          subtitle: [
            "training:blocks:lte:subtitle:line1",
            "training:blocks:lte:subtitle:line2",
          ],
          explanation: "training:blocks:lte:explanation",
          items: [
            "training:blocks:lte:item1",
            "training:blocks:lte:item2",
            "training:blocks:lte:item3",
            "training:blocks:lte:item4",
          ],
        },
      },
    },
    pricing: {
      header: {
        title: ["pricing:header:line1", "pricing:header:line2"],
        explanation: "pricing:header:explanation",
        img: {
          alt: "world",
          url:
            "https://www.coachtrainingedu.com/wp-content/uploads/elementor/thumbs/image-7-p4flwiws703ic0nsjbvm8i9zms72gjpz6lxpgzmh0w.png?189db0&189db0",
        },
      },
      levelsPrices: {
        titles: [
          "pricing:levelsPrices:titles:title1",
          "pricing:levelsPrices:titles:title2",
          "pricing:levelsPrices:titles:title3",
        ],
        descriptions: [
          "pricing:levelsPrices:descriptions:description1",
          "pricing:levelsPrices:descriptions:description2",
          "pricing:levelsPrices:descriptions:description3",
        ],
      },
      questionPrices: {
        title: "pricing:questionPrices:title",
        button: "pricing:questionPrices:button",
      },
      table: {
        title: "pricing:table:title",
        headers: [
          "pricing:table:headers:header1",
          "pricing:table:headers:header2",
          "pricing:table:headers:header3",
        ],
        rows: {
          row1: [
            "pricing:table:rows:row1:td1",
            "pricing:table:rows:row1:td2",
            "pricing:table:rows:row1:td3",
            "pricing:table:rows:row1:td4",
          ],
          row2: [
            "pricing:table:rows:row2:td1",
            "pricing:table:rows:row2:td2",
            "pricing:table:rows:row2:td3",
            "pricing:table:rows:row2:td4",
          ],
          row3: [
            "pricing:table:rows:row3:td1",
            "pricing:table:rows:row3:td2",
            "pricing:table:rows:row3:td3",
            "pricing:table:rows:row3:td4",
          ],
          row4: [
            "pricing:table:rows:row4:td1",
            "pricing:table:rows:row4:td2",
            "pricing:table:rows:row4:td3",
            "pricing:table:rows:row4:td4",
          ],
          row5: [
            "pricing:table:rows:row5:td1",
            "pricing:table:rows:row5:td2",
            "pricing:table:rows:row5:td3",
            "pricing:table:rows:row5:td4",
          ],
          row6: [
            "pricing:table:rows:row6:td1",
            "pricing:table:rows:row6:td2",
            "pricing:table:rows:row6:td3",
            "pricing:table:rows:row6:td4",
          ],
          row7: [
            "pricing:table:rows:row7:td1",
            "pricing:table:rows:row7:td2",
            "pricing:table:rows:row7:td3",
            "pricing:table:rows:row7:td4",
          ],
          row8: [
            "pricing:table:rows:row8:td1",
            "pricing:table:rows:row8:td2",
            "pricing:table:rows:row8:td3",
            "pricing:table:rows:row8:td4",
          ],
          row9: [
            "pricing:table:rows:row9:td1",
            "pricing:table:rows:row9:td2",
            "pricing:table:rows:row9:td3",
            "pricing:table:rows:row9:td4",
          ],
          row10: [
            "pricing:table:rows:row10:td1",
            "pricing:table:rows:row10:td2",
            "pricing:table:rows:row10:td3",
            "pricing:table:rows:row10:td4",
          ],
          row11: [
            "pricing:table:rows:row11:td1",
            "pricing:table:rows:row11:td2",
            "pricing:table:rows:row11:td3",
            "pricing:table:rows:row11:td4",
          ],
          row12: [
            "pricing:table:rows:row12:td1",
            "pricing:table:rows:row12:td2",
            "pricing:table:rows:row12:td3",
            "pricing:table:rows:row12:td4",
          ],
          row13: [
            "pricing:table:rows:row13:td1",
            "pricing:table:rows:row13:td2",
            "pricing:table:rows:row13:td3",
            "pricing:table:rows:row13:td4",
          ],
          row14: [
            "pricing:table:rows:row14:td1",
            "pricing:table:rows:row14:td2",
            "pricing:table:rows:row14:td3",
            "pricing:table:rows:row14:td4",
          ],
          row15: [
            "pricing:table:rows:row15:td1",
            "pricing:table:rows:row15:td2",
            "pricing:table:rows:row15:td3",
            "pricing:table:rows:row15:td4",
          ],
          row16: [
            "pricing:table:rows:row16:td1",
            "pricing:table:rows:row16:td2",
            "pricing:table:rows:row16:td3",
            "pricing:table:rows:row16:td4",
          ],
          row17: [
            "pricing:table:rows:row17:td1",
            "pricing:table:rows:row17:td2",
            "pricing:table:rows:row17:td3",
            "pricing:table:rows:row17:td4",
          ],
          row18: [
            "pricing:table:rows:row18:td1",
            "pricing:table:rows:row18:td2",
            "pricing:table:rows:row18:td3",
            "pricing:table:rows:row18:td4",
          ],
          row19: [
            "pricing:table:rows:row19:td1",
            "pricing:table:rows:row19:td2",
            "pricing:table:rows:row19:td3",
            "pricing:table:rows:row19:td4",
          ],
          row20: [
            "pricing:table:rows:row20:td1",
            "pricing:table:rows:row20:td2",
            "pricing:table:rows:row20:td3",
            "pricing:table:rows:row20:td4",
          ],
          row21: [
            "pricing:table:rows:row21:td1",
            "pricing:table:rows:row21:td2",
            "pricing:table:rows:row21:td3",
            "pricing:table:rows:row21:td4",
          ],
          row22: [
            "pricing:table:rows:row22:td1",
            "pricing:table:rows:row22:td2",
            "pricing:table:rows:row22:td3",
            "pricing:table:rows:row22:td4",
          ],
          row23: [
            "pricing:table:rows:row23:td1",
            "pricing:table:rows:row23:td2",
            "pricing:table:rows:row23:td3",
            "pricing:table:rows:row23:td4",
          ],
          row24: [
            "pricing:table:rows:row24:td1",
            "pricing:table:rows:row24:td2",
            "pricing:table:rows:row24:td3",
            "pricing:table:rows:row24:td4",
          ],
          row25: [
            "pricing:table:rows:row25:td1",
            "pricing:table:rows:row25:td2",
            "pricing:table:rows:row25:td3",
            "pricing:table:rows:row25:td4",
          ],
          row26: [
            "pricing:table:rows:row26:td1",
            "pricing:table:rows:row26:td2",
            "pricing:table:rows:row26:td3",
            "pricing:table:rows:row26:td4",
          ],
        },
      },
      levelsDetails: {
        title: "pricing:levelsDetails:title",
        subtitles: [
          "pricing:levelsDetails:subtitles:subtitle1",
          "pricing:levelsDetails:subtitles:subtitle2",
          "pricing:levelsDetails:subtitles:subtitle3",
        ],
        descriptions: {
          description1: [
            "pricing:levelsDetails:descriptions:description1:item1",
            "pricing:levelsDetails:descriptions:description1:item2",
            "pricing:levelsDetails:descriptions:description1:item3",
            "pricing:levelsDetails:descriptions:description1:item4",
          ],
          description2: [
            "pricing:levelsDetails:descriptions:description2:item1",
            "pricing:levelsDetails:descriptions:description2:item2",
            "pricing:levelsDetails:descriptions:description2:item3",
            "pricing:levelsDetails:descriptions:description2:item4",
          ],
          description3: [
            "pricing:levelsDetails:descriptions:description3:item1",
            "pricing:levelsDetails:descriptions:description3:item2",
            "pricing:levelsDetails:descriptions:description3:item3",
            "pricing:levelsDetails:descriptions:description3:item4",
          ],
        },
      },
      block: {
        packages: {
          icon: "",
          title: ["pricing:block:packages:title:line1"],
          subtitle: [""],
          explanation: "pricing:block:packages:explanation",
          items: [
            "pricing:block:packages:item1",
            "pricing:block:packages:item2",
            "pricing:block:packages:item3",
            "pricing:block:packages:item4",
          ],
        },
      },
      videos: [
        "https://youtu.be/gcC4hG_SmTU",
        "https://youtu.be/0rYFc0Pmb64",
        "https://youtu.be/Jw5AKlKT1UE",
      ],
    },
    contact: {
      header: {
        title: ["contact:header:line1", "contact:header:line2"],
        explanation: "contact:header:explanation",
      },
    },
    home: {
      banner: {
        title: ["home:banner:line1", "home:banner:line2"],
      },
      header: {
        title: ["home:header:line1", "home:header:line2", "home:header:line3"],
        explanation: "home:header:explanation",
      },
      videos: [
        "https://youtu.be/gcC4hG_SmTU",
        "https://youtu.be/0rYFc0Pmb64",
        "https://youtu.be/Jw5AKlKT1UE",
      ],
      block: {
        certification: {
          title: ["home:blocks:certification:title"],
          subtitle: ["home:blocks:certification:subtitle"],
          explanation: [
            "home:blocks:certification:explanation:line1",
            "home:blocks:certification:explanation:line2",
          ],
        },
        info: {
          title: ["home:blocks:info:title:line1"],
          subtitle: ["home:blocks:info:subtitle:line1"],
          explanation: "home:blocks:info:explanation",
          items: [
            "home:blocks:info:item1",
            "home:blocks:info:item2",
            "home:blocks:info:item3",
            "home:blocks:info:item4",
          ],
        },
        community: {
          title: ["home:blocks:community:title:line1"],
          subtitle: ["home:blocks:community:subtitle:line1"],
          explanation: "home:blocks:community:explanation",
          items: [
            "home:blocks:community:item1",
            "home:blocks:community:item2",
            "home:blocks:community:item3",
            "home:blocks:community:item4",
          ],
        },
        quiz: {
          title: "home:blocks:quiz:title",
          subtitle: "home:blocks:quiz:subtitle",
          explanation: "home:blocks:quiz:explanation",
          items: [
            "home:blocks:quiz:item1",
            "home:blocks:quiz:item2",
            "home:blocks:quiz:item3",
            "home:blocks:quiz:item4",
          ],
        },
      },
    },
    academic: {
      header: {
        title: ["academic:header:title:line1", "academic:header:title:line2"],
        explanation: "academic:header:explanation",
        img: {
          alt: "world",
          url:
            "https://www.coachtrainingedu.com/wp-content/uploads/elementor/thumbs/image-7-p4flwiws703ic0nsjbvm8i9zms72gjpz6lxpgzmh0w.png?189db0&189db0",
        },
        paragraph: "academic:header:paragraph",
      },
      videos: [
        "https://youtu.be/gcC4hG_SmTU",
        "https://youtu.be/0rYFc0Pmb64",
        "https://youtu.be/Jw5AKlKT1UE",
      ],
      blocks: {
        train: {
          title: ["academic:blocks:train:title:line1"],
          subtitle: ["academic:blocks:train:subtitle:line1"],
          explanation: "academic:blocks:train:explanation",
          items: [
            "academic:blocks:train:item1",
            "academic:blocks:train:item2",
            "academic:blocks:train:item3",
            "academic:blocks:train:item4",
          ],
        },
      },
      info: {
        title: "academic:info:title",
        subtitle: "academic:info:subtitle",
        explanation: "academic:info:explanation",
      },
      weekTrialButton: "academic:weekTrialButton",

      academicBlocks: {
        independent: {
          title: [
            "academic:academicBlocks:independent:title:line1",
            "academic:academicBlocks:independent:title:line2",
          ],
          explanation: "academic:academicBlocks:independent:explanation",
          items: [
            "academic:academicBlocks:independent:item1",
            "academic:academicBlocks:independent:item2",
            "academic:academicBlocks:independent:item3",
          ],
        },
        schoolsCoach: {
          title: [
            "academic:academicBlocks:schoolsCoach:title:line1",
            "academic:academicBlocks:schoolsCoach:title:line2",
          ],
          explanation: "academic:academicBlocks:schoolsCoach:explanation",
          items: [
            "academic:academicBlocks:schoolsCoach:item1",
            "academic:academicBlocks:schoolsCoach:item2",
            "academic:academicBlocks:schoolsCoach:item3",
          ],
        },
        trainTeam: {
          title: [
            "academic:academicBlocks:trainTeam:title:line1",
            "academic:academicBlocks:trainTeam:title:line2",
          ],
          explanation: "academic:academicBlocks:trainTeam:explanation",
          items: [
            "academic:academicBlocks:trainTeam:item1",
            "academic:academicBlocks:trainTeam:item2",
            "academic:academicBlocks:trainTeam:item3",
          ],
        },
      },
    },
    certification: {
      header: {
        title: ["certification:header:line1", "certification:header:line2"],
      },
      videos: [
        "https://youtu.be/gcC4hG_SmTU",
        "https://youtu.be/0rYFc0Pmb64",
        "https://youtu.be/Jw5AKlKT1UE",
      ],
      blocks: {
        certification: {
          title: [
            "certification:blocks:certification:title:line1",
            "certification:blocks:certification:title:line2",
          ],
          subtitle: [
            "certification:blocks:certification:subtitle:line1",
            "certification:blocks:certification:subtitle:line2",
          ],
          explanation: "certification:blocks:certification:explanation",
        },
        info: {
          title: [
            "certification:blocks:info:title:line1",
            "certification:blocks:info:title:line2",
          ],
          subtitle: [
            "certification:blocks:info:subtitle:line1",
            "certification:blocks:info:subtitle:line2",
          ],
          explanation: "certification:blocks:info:explanation",
          items: [
            "certification:blocks:info:item1",
            "certification:blocks:info:item2",
            "certification:blocks:info:item3",
            "certification:blocks:info:item4",
          ],
        },
        programs: {
          title: [
            "certification:blocks:programs:title:line1",
            "certification:blocks:programs:title:line2",
          ],
          subtitle: [
            "certification:blocks:programs:subtitle:line1",
            "certification:blocks:programs:subtitle:line2",
            "certification:blocks:programs:subtitle:line3",
          ],
          explanation: [
            "certification:blocks:programs:explanation:line1",
            "certification:blocks:programs:explanation:line2",
            "certification:blocks:programs:explanation:line3",
          ],
          item1: [
            "certification:blocks:programs:item1:line1",
            "certification:blocks:programs:item1:line2",
            "certification:blocks:programs:item1:line3",
            "certification:blocks:programs:item1:line4",
          ],
          item2: [
            "certification:blocks:programs:item2:line1",
            "certification:blocks:programs:item2:line2",
            "certification:blocks:programs:item2:line3",
            "certification:blocks:programs:item2:line4",
          ],
          item3: [
            "certification:blocks:programs:item3:line1",
            "certification:blocks:programs:item3:line2",
            "certification:blocks:programs:item3:line3",
            "certification:blocks:programs:item3:line4",
          ],
        },
        trial: {
          title: "certification:blocks:trial:title",
          subtitle: "certification:blocks:trial:subtitle",
          explanation: "certification:blocks:trial:explanation",
          items: [
            "certification:blocks:trial:item1",
            "certification:blocks:trial:item2",
            "certification:blocks:trial:item3",
            "certification:blocks:trial:item4",
          ],
        },
        process: {
          title: ["certification:blocks:process:title:line1"],
          subtitle: [
            "certification:blocks:process:subtitle:line1",
            "certification:blocks:process:subtitle:line2",
            "certification:blocks:process:subtitle:line3",
            "certification:blocks:process:subtitle:line4",
            "certification:blocks:process:subtitle:line5",
          ],
          explanation: [
            "certification:blocks:process:explanation:line1",
            "certification:blocks:process:explanation:line2",
            "certification:blocks:process:explanation:line3",
            "certification:blocks:process:explanation:line4",
            "certification:blocks:process:explanation:line5",
            "certification:blocks:process:explanation:line6",
            "certification:blocks:process:explanation:line7",
          ],
          steps: [
            "certification:blocks:process:steps:line1",
            "certification:blocks:process:steps:line2",
            "certification:blocks:process:steps:line3",
            "certification:blocks:process:steps:line4",
            "certification:blocks:process:steps:line5",
          ],
        },
        advcourses: {
          title: [
            "certification:blocks:advcourses:title:line1",
            "certification:blocks:advcourses:title:line2",
          ],
          subtitle: [
            "certification:blocks:advcourses:subtitle:line1",
            "certification:blocks:advcourses:subtitle:line2",
          ],
          explanation: [
            "certification:blocks:advcourses:explanation:line1",
            "certification:blocks:advcourses:explanation:line2",
          ],
        },
      },
    },
    faq: {
      header: {
        title: ["faqs:header:line1", "faqs:header:line2"],
        explanation: "faqs:header:explanation",
      },
    },
    schedule: {
      header: {
        title: ["schedule:header:line1", "schedule:header:line2"],
        explanation: "schedule:header:explanation",
      },
    },
    advisor: {
      header: {
        title: ["advisor:header:line1", "advisor:header:line2"],
        explanation: "advisor:header:explanation",
      },
    },
    diversity: {
      header: {
        title: ["diversity:header:line1", "diversity:header:line2"],
        explanation: "diversity:header:explanation",
      },
    },
    learning: {
      header: {
        title: ["learning:header:line1", "learning:header:line2"],
        explanation: "learning:header:explanation",
      },
    },
    grads: {
      header: {
        title: ["grads:header:line1", "grads:header:line2"],
        explanation: "grads:header:explanation",
      },
    },
  },
};
