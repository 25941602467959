import React from "react";
import { Header } from "../components/commons/Header";
import { useTranslation } from "react-i18next";
import { Settings } from "../app.config";
import { Video } from "../components/commons/Video";
import { Block } from "../components/commons/Block";
import { AcademicBlock } from "../components/Content/AcademicContent/AcademicBlock";

export const Academic = () => {
  const elements = Settings.pages.academic;

  const { t } = useTranslation();

  return (
    <div>
      <Header data={elements}></Header>
      <div className="flex justify-center">
        <p className="text-center p-1 max-w-lg text-xl text-gray-700  my-10">
          {t(elements.header.paragraph)}
        </p>
      </div>

      <Video videos={elements.videos}></Video>
      <Block data={elements.blocks.train}></Block>
      <div className="flex justify-center ">
        <button className="p-2 rounded-full  text-white bg-orange-title text-center">
          {t(elements.weekTrialButton)}
        </button>
      </div>

      <div className=" container mx-auto w-3/4 mt-10 ">
        <h2 className="text-center font-serif text-gray-700 font-serif p-6  text-4xl">
          {t(elements.info.title)}
        </h2>

        <h3 className="text-center p-1  text-2xl font-oswald  text-orange-title">
          {t(elements.info.subtitle)}
        </h3>
        <p className="text-center  text-gray-700  my-10 ">
          {t(elements.info.explanation)}
        </p>
      </div>

      <AcademicBlock
        data={elements.academicBlocks.independent}
        video={elements.videos[1]}
      ></AcademicBlock>
      <AcademicBlock
        reverse
        data={elements.academicBlocks.schoolsCoach}
        video={elements.videos[0]}
      ></AcademicBlock>
      <AcademicBlock
        data={elements.academicBlocks.trainTeam}
        video={elements.videos[2]}
      ></AcademicBlock>
    </div>
  );
};
