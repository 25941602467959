import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import loadable from "@loadable/component";

import { Header } from '../components/commons/Header';
import { Block } from "../components/commons/Block";
import { Content } from "../components/Content/HomeContent/Content";
import { Quiz } from "../components/Content/HomeContent/Quiz";

import { Settings } from "../app.config";

const Video = loadable(() => import("../components/commons/Video"));

export const Home = () => {
  const { t } = useTranslation();
  const elements = Settings.pages.home;
  return (
    <div>
      <Content />

      <Header data={elements}></Header>

        <div className="py-6 flex flex-col items-center">
      <div className="w-2/4 text-center flex flex-col items-center">
        <img
          alt="img"
          className="my-5"
          src="https://www.coachtrainingedu.com/wp-content/uploads/2020/12/logo-all-black-and-white.png"
          loading="lazy"
        />
        <p className="mx-10 text-lg my-10">
          {t(elements.block.certification.explanation[0])}
        </p>
        <Link
          className="bg-yellow-600 my-10 py-1 px-4 rounded-full text-white uppercase"
          to="/"
        >
          Get started
        </Link>
        <p className="mx-10 text-lg my-10">
          {t(elements.block.certification.explanation[1])}
        </p>
      </div>
      <img
        alt="img"
        className="my-5"
        src="https://www.coachtrainingedu.com/wp-content/uploads/elementor/thumbs/Logos-for-homepage-3.19.21-copy-p4gh96mjpvvd7uffrcprd267r8df7j8dw85f5kv6bi.jpg?189db0&189db0"
        loading="lazy"
      />
    </div>


      {/* <Certifications /> */}
      <Block data={elements.block.info} />
      <Quiz />
      <Block data={elements.block.community} />
      <Video videos={elements.videos} />
    </div>
  );
};