import React from 'react'
import { Link } from 'react-router-dom'

export const ApplyBtn = () => {
    return (
        <div className="flex items-center mx-6">
            <Link to="/" className="px-10 py-2 uppercase font-highlight font-semibold leading-relaxed text-gray-700 bg-yellow-200 rounded-full">Apply</Link>
        </div>
    )
}
