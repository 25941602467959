import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { ApplyBtn } from "./ApplyBtn";
import { Dropdown } from './Dropdown';
import { LanguageSwitcher } from "./LanguageSwitcher";

import { Settings } from '../../../../app.config';

export const Navbar = () => {

  const [click, setClick] = useState(false);
  const [dropdowns, setDropdowns] = useState({})
  const { t } = useTranslation();

  useEffect(() => {
    let newDropdowns = {}
    Settings.menu.left.map((item, index) => { 
      if (item.type === 'Dropdown') {
        newDropdowns[item.id] = false;
      }
      return true;
    });
    Settings.menu.right.map((item, index) => { 
      if (item.type === 'Dropdown') {
        newDropdowns[item.id] = false;
      }
      return true;
    });
    setDropdowns({...newDropdowns});
  }, []);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const onMouseEnter = (e) => {
    dropdowns[e.target.id] = true;
    setDropdowns({...dropdowns});
  };

  const onMouseLeave = () => {
    Object.keys(dropdowns).forEach((key) => {dropdowns[key] = false;});
    setDropdowns({...dropdowns});
  };

  const renderMenuItems = (items) => {
    return (
      items.map((item, index) => {
        if (item.type === 'Link') {
          return (
            <li key={item.id} id={item.id}>
              <Link
                to={item.path}
                className="p-5 uppercase font-menu text-gray-700 whitespace-nowrap"
                onClick={closeMobileMenu}
              >
                {t(item.title)}
              </Link>
            </li>
          )
        } else if (item.type === 'Dropdown') {
          if (item.subtype === 'language') {
            return (
              <li
                key={item.id}
                id = {item.id}
                className="p-5 flex flex-col items-center uppercase font-menu text-gray-700 whitespace-nowrap"
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
              >
                <i className="fas fa-globe w-20 text-xl" ></i>
                {dropdowns[item.id] && <LanguageSwitcher menuItems={Settings.languages} handler={onMouseLeave} />}
              </li>
            )
          } else {
            return (
              <li
                key={item.id}
                id = {item.id}
                className="p-5 flex flex-col items-center uppercase font-menu text-gray-700 whitespace-nowrap"
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
              >
                <Link to={item.path} className="nav-links" onClick={closeMobileMenu}>
                  {t(item.title)} <i className="fas fa-caret-down" />
                </Link>
                {dropdowns[item.id] && <Dropdown menuItems={item.items} handler={onMouseLeave} />}
              </li>
            )
          }
        } 
        return true;
      })
    );
  }

  return (
    <>
      <nav className="flex justify-between text-center items-center h-24 text-black relative shadow-md font-serif z-10">
        
        {/** Left Elements */}
        <div className="flex-initial">
          <div className="flex justify-start items-center relative">

            {/** Logo */}
            <Link to="/" onClick={closeMobileMenu}>
              <img
                className="lg:w-20 sm:w-16 w-14 m-5 justify-self-start"
                src="https://www.coachtrainingedu.com/wp-content/uploads/2020/09/edu-logo-5-150x150-1.png?189db0&189db0"
                alt="logo"
              />{" "}
            </Link>

            {/** Mobile Menu */}
            <div className="px-4 cursor-pointer md:hidden" onClick={handleClick}>
              <i className={click ? "fas fa-times" : "fas fa-bars"} />
            </div>

            {/* Menu Elements */}
    
            <div className="flex items-center justify-self-start justify-between">
              <div>
                  <ul className="flex items-center">
                    {renderMenuItems(Settings.menu.left)}
                </ul>
              </div>
            </div>  
          </div>
        </div>

        {/* Right Elements */}
        <div className="flex-initial">
          <div className="flex justify-end items-center relative">
              <div>
                  <ul className="flex items-center">
                    {renderMenuItems(Settings.menu.right)}
                </ul>
              </div>
            <ApplyBtn />
          </div>
        </div>
      </nav>
    </>
  );
};
