import React from "react";
import { Link } from "react-router-dom";

const TrainingPrograms = () => {
  return (
    <div className="text-center my-8 lg:my-40 ">
      <div className=" mx-20 grid grid-cols-3 gap-y-5">
        <img
          className="  mx-auto  "
          src="https://www.coachtrainingedu.com/wp-content/uploads/2020/12/Excecutive-1.png?189db0&189db0"
          alt="executive"
        ></img>

        <img
          className="mx-auto"
          src="https://www.coachtrainingedu.com/wp-content/uploads/2020/12/Wellness-2.png"
          alt="wellness"
        ></img>

        <img
          className="mx-auto"
          src="https://www.coachtrainingedu.com/wp-content/uploads/2020/12/Academic-1.png?189db0&189db0"
          alt="academic"
        ></img>

        <div>
          <p className="text-gray-700  mx-20 my-5">
            For coaches who want to work with executives and entrepreneurs to
            spark creativity, maintain work-life balance, and tap into
            professional and personal growth and potential.
          </p>
        </div>
        <div>
          <p className="text-gray-700  mx-20 my-5">
            For coaches wanting a holistic coach training program and tools and
            guidelines to explore topics of health and wellness, from exercise
            to positive and sustainable lifestyle change.
          </p>
        </div>
        <div>
          <p className="text-gray-700 mx-20 my-5">
            For coaches, educators, and advisors yearning to use coaching
            concepts and tools with students and young adults to equip young
            people to lead fulfilled, effective lives.
          </p>
        </div>
        <div>
          <Link to="/">
            <h4 className="transition  duration-500 ease-in-out bg-button-training hover:bg-button-hover-training text-white rounded-full font-light font-mono py-2 inline p-5">
              EXECUTIVE
            </h4>
          </Link>
        </div>
        <div>
          <Link to="/">
            <h4 className="transition  duration-500 ease-in-out bg-button-training hover:bg-button-hover-training text-white rounded-full font-light font-mono py-2 inline p-5">
              WELLNESS
            </h4>
          </Link>
        </div>

        <div>
          <Link to="/">
            <h4 className="transition  duration-500 ease-in-out bg-button-training hover:bg-button-hover-training text-white rounded-full font-light font-mono py-2 inline p-5">
              ACADEMIC
            </h4>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default TrainingPrograms;
