import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const Dropdown = ( props ) => {

  const [click, setClick] = useState(false);
  const { t } = useTranslation();
  
  const handleClick = () => {
    setClick(!click);
    props.handler();
  }

  return (
    <>
      <ul
        onClick={handleClick}
        onMouseLeave={handleClick}
        className="absolute flex flex-col mt-10 bg-blue items-center"
      >
        {props.menuItems.map((item, index) => {
          return (
            <li
              key={index}
              className="flex text-white hover:bg-blue-light-less w-full"
            >
              <Link
                className="m-4"
                to={item.path}
                onClick={() => setClick(false)}
              >
                {t(item.title)}
              </Link>
            </li>
          );
        })}
      </ul>
    </>
  );
};