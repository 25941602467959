import React from "react";
import { useTranslation } from "react-i18next";
import { Settings } from "../app.config";
import { Block } from "../components/commons/Block";
import { Video } from "../components/commons/Video";
import { Header } from "../components/commons/Header";

export const Pricing = () => {
  const elements = Settings.pages.pricing;

  const { t } = useTranslation();

  return (
    <div>
      <Header data={elements}></Header>
      <Video videos={elements.videos} />
      <Block data={elements.block.packages} />

      <div className="container mx-auto text-left w-9/12 ">
        <div className=" grid  grid-cols-3  ">
          <img
            src="https://www.coachtrainingedu.com/wp-content/uploads/2021/01/Executive.png?189db0&189db0"
            alt=""
          />
          <div className=" col-span-2">
            <h1 className="  text-2xl font-serif  text-orange-title">
              {t(elements.levelsPrices.titles[0])}
            </h1>
            <div className="max-w-prose my-2">
              <p>{t(elements.levelsPrices.descriptions[0])}</p>
            </div>
          </div>

          <img
            src="https://www.coachtrainingedu.com/wp-content/uploads/2021/01/2.0.png?189db0&189db0"
            alt=""
          />
          <div className="col-span-2">
            <h1 className="text-2xl font-serif  text-orange-title">
              {" "}
              {t(elements.levelsPrices.titles[1])}
            </h1>
            <div className="max-w-prose my-2">
              <p>{t(elements.levelsPrices.descriptions[1])}</p>
            </div>
          </div>
          <img
            src="https://www.coachtrainingedu.com/wp-content/uploads/2021/01/Elective.png?189db0&189db0"
            alt=""
          />
          <div className="col-span-2">
            <h1 className="text-left  text-2xl font-serif  text-orange-title">
              {t(elements.levelsPrices.titles[2])}
            </h1>
            <div className="max-w-prose my-2">
              <p>{t(elements.levelsPrices.descriptions[2])}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="my-20 text-2xl  ">
        <h1 className=" text-center  font-serif  ">
          {t(elements.questionPrices.title)}
        </h1>
        <div className=" my-5 flex justify-center">
          <button className=" text-white  p-2  rounded-md bg-orange-title">
            {t(elements.questionPrices.button)}
          </button>
        </div>
      </div>

      <div className="flex justify-center flex-col">
        <h2 className="text-center font-normal text-2xl text-gray-700 font-serif p-6  lg:text-5xl">
          {t(elements.table.title)}
        </h2>
        <table className="  container mx-auto   rounded-t-lg m-5 w-3/5  text-gray-800  ">
          <tbody>
            <tr className="  text-left   ">
              <th className="w-1/2    "></th>
              <th className=" w-1/6  text-2xl py-3   ">
                {t(elements.table.headers[0])}
              </th>
              <th className=" w-1/6  text-2xl  px-4 ">
                {t(elements.table.headers[1])}
              </th>
              <th className=" w-1/6  text-2xl  px-4">
                {t(elements.table.headers[2])}
              </th>
            </tr>
            <tr className=" border-b  ">
              <td className=" text-right px-10 py-10 ">
                {t(elements.table.rows.row1[0])}
              </td>
              <td className="px-4  text-orange-title ">
                {t(elements.table.rows.row1[1])}
              </td>
              <td className="px-4  text-orange-title">
                {t(elements.table.rows.row1[2])}
              </td>
              <td className="px-4   text-orange-title">
                {t(elements.table.rows.row1[3])}
              </td>
            </tr>
            <tr className=" border-b  ">
              <td className=" text-right px-10 py-10 ">
                {t(elements.table.rows.row2[0])}
              </td>
              <td className="px-4  text-orange-title ">
                {t(elements.table.rows.row2[1])}
              </td>
              <td className="px-4 text-orange-title ">
                {t(elements.table.rows.row2[2])}
              </td>
              <td className="px-4 text-orange-title ">
                {t(elements.table.rows.row2[3])}
              </td>
            </tr>
            <tr className=" border-b  ">
              <td className=" text-right px-10 py-10 ">
                {t(elements.table.rows.row3[0])}
              </td>
              <td className="px-4 py-3 text-orange-title ">
                {t(elements.table.rows.row3[1])}
              </td>
              <td className="px-4 py-3 text-orange-title">
                {t(elements.table.rows.row3[2])}
              </td>
              <td className="px-4 py-3 text-orange-title">
                {t(elements.table.rows.row3[3])}
              </td>
            </tr>
            <tr className=" border-b  ">
              <td className=" text-right px-10 py-10 ">
                {t(elements.table.rows.row4[0])}
              </td>
              <td className="px-4 py-3 text-orange-title">
                {t(elements.table.rows.row4[1])}
              </td>
              <td className="px-4 py-3 text-orange-title">
                {t(elements.table.rows.row4[2])}
              </td>
              <td className="px-4 py-3 text-orange-title">
                {t(elements.table.rows.row4[3])}
              </td>
            </tr>
            <tr className=" border-b  ">
              <td className=" text-right px-10 py-10 ">
                {t(elements.table.rows.row5[0])}
              </td>
              <td className="px-4 py-3 text-orange-title">
                {t(elements.table.rows.row5[1])}
              </td>
              <td className="px-4 py-3 text-orange-title">
                {t(elements.table.rows.row5[2])}
              </td>
              <td className="px-4 py-3 text-orange-title">
                {t(elements.table.rows.row5[3])}
              </td>
            </tr>
            <tr className=" border-b  ">
              <td className=" text-right px-10 py-10 ">
                {t(elements.table.rows.row6[0])}
              </td>
              <td className="px-4 py-3 text-orange-title">
                {t(elements.table.rows.row6[1])}
              </td>
              <td className="px-4 py-3 text-orange-title">
                {t(elements.table.rows.row6[2])}
              </td>
              <td className="px-4 py-3 text-orange-title">
                {t(elements.table.rows.row6[3])}
              </td>
            </tr>
            <tr className=" border-b  ">
              <td className=" text-right px-10 py-10 ">
                {t(elements.table.rows.row7[0])}
              </td>
              <td className="px-4 py-3 text-orange-title">
                {" "}
                {t(elements.table.rows.row7[1])}
              </td>
              <td className="px-4 py-3 text-orange-title">
                {t(elements.table.rows.row7[2])}
              </td>
              <td className="px-4 py-3 text-orange-title">
                {t(elements.table.rows.row7[3])}
              </td>
            </tr>
            <tr className=" border-b  ">
              <td className=" text-right px-10 py-10 ">
                {t(elements.table.rows.row8[0])}
              </td>
              <td className="px-4 py-3 text-orange-title">
                {t(elements.table.rows.row8[1])}
              </td>
              <td className="px-4 py-3 text-orange-title">
                {t(elements.table.rows.row8[2])}
              </td>
              <td className="px-4 py-3 text-orange-title">
                {t(elements.table.rows.row8[3])}
              </td>
            </tr>
            <tr className=" border-b  ">
              <td className=" text-right px-10 py-10 ">
                {t(elements.table.rows.row9[0])}
              </td>
              <td className="px-4 py-3 text-orange-title">
                {t(elements.table.rows.row9[1])}
              </td>
              <td className="px-4 py-3 text-orange-title">
                {t(elements.table.rows.row9[2])}
              </td>
              <td className="px-4 py-3 text-orange-title">
                {t(elements.table.rows.row9[3])}
              </td>
            </tr>
            <tr className=" border-b  ">
              <td className=" text-right px-10 py-10 ">
                {t(elements.table.rows.row10[0])}
              </td>
              <td className="px-4 py-3 text-orange-title">
                {t(elements.table.rows.row10[1])}
              </td>
              <td className="px-4 py-3 text-orange-title">
                {t(elements.table.rows.row10[2])}
              </td>
              <td className="px-4 py-3 text-orange-title">
                {t(elements.table.rows.row10[3])}
              </td>
            </tr>
            <tr className=" border-b  ">
              <td className=" text-right px-10 py-10 ">
                {t(elements.table.rows.row11[0])}
              </td>
              <td className="px-4 py-3 text-orange-title">
                {t(elements.table.rows.row11[1])}
              </td>
              <td className="px-4 py-3 text-orange-title">
                {t(elements.table.rows.row11[2])}
              </td>
              <td className="px-4 py-3 text-orange-title">
                {t(elements.table.rows.row11[3])}
              </td>
            </tr>
            <tr className=" border-b  ">
              <td className=" text-right px-10 py-10 ">
                {t(elements.table.rows.row12[0])}
              </td>
              <td className="px-4 py-3 text-orange-title">
                {t(elements.table.rows.row12[1])}
              </td>
              <td className="px-4 py-3 text-orange-title">
                {t(elements.table.rows.row12[2])}
              </td>
              <td className="px-4 py-3 text-orange-title">
                {t(elements.table.rows.row12[3])}
              </td>
            </tr>
            <tr className=" border-b  ">
              <td className=" text-right px-10 py-10 ">
                {t(elements.table.rows.row13[0])}
              </td>
              <td className="px-4 py-3 text-orange-title">
                {t(elements.table.rows.row13[1])}
              </td>
              <td className="px-4 py-3 text-orange-title">
                {t(elements.table.rows.row13[2])}
              </td>
              <td className="px-4 py-3 text-orange-title">
                {t(elements.table.rows.row13[3])}
              </td>
            </tr>
            <tr className=" border-b  ">
              <td className=" text-right px-10 py-10 ">
                {t(elements.table.rows.row14[0])}
              </td>
              <td className="px-4 py-3 text-orange-title">
                {t(elements.table.rows.row14[1])}
              </td>
              <td className="px-4 py-3 text-orange-title">
                {t(elements.table.rows.row14[2])}
              </td>
              <td className="px-4 py-3 text-orange-title">
                {t(elements.table.rows.row14[3])}
              </td>
            </tr>
            <tr className=" border-b  ">
              <td className=" text-right px-10 py-10 ">
                {t(elements.table.rows.row15[0])}
              </td>
              <td className="px-4 py-3 text-orange-title">
                {t(elements.table.rows.row15[1])}
              </td>
              <td className="px-4 py-3 text-orange-title">
                {t(elements.table.rows.row15[2])}
              </td>
              <td className="px-4 py-3 text-orange-title">
                {t(elements.table.rows.row15[3])}
              </td>
            </tr>
            <tr className=" border-b  ">
              <td className=" text-right px-10 py-10 ">
                {t(elements.table.rows.row16[0])}
              </td>
              <td className="px-4 py-3 text-orange-title">
                {t(elements.table.rows.row16[1])}
              </td>
              <td className="px-4 py-3 text-orange-title">
                {t(elements.table.rows.row16[2])}
              </td>
              <td className="px-4 py-3 text-orange-title">
                {t(elements.table.rows.row16[3])}
              </td>
            </tr>
            <tr className=" border-b  ">
              <td className=" text-right px-10 py-10 ">
                {t(elements.table.rows.row17[0])}
              </td>
              <td className="px-4 py-3 text-orange-title">
                {t(elements.table.rows.row17[1])}
              </td>
              <td className="px-4 py-3 text-orange-title">
                {t(elements.table.rows.row17[2])}
              </td>
              <td className="px-4 py-3 text-orange-title">
                {t(elements.table.rows.row17[3])}
              </td>
            </tr>
            <tr className=" border-b  ">
              <td className=" text-right px-10 py-10 ">
                {t(elements.table.rows.row18[0])}
              </td>
              <td className="px-4 py-3 text-orange-title">
                {t(elements.table.rows.row18[1])}
              </td>
              <td className="px-4 py-3 text-orange-title">
                {t(elements.table.rows.row18[2])}
              </td>
              <td className="px-4 py-3 text-orange-title">
                {t(elements.table.rows.row18[3])}
              </td>
            </tr>
            <tr className=" border-b  ">
              <td className=" text-right px-10 py-10 ">
                {t(elements.table.rows.row19[0])}
              </td>
              <td className="px-4 py-3 text-orange-title">
                {t(elements.table.rows.row19[1])}
              </td>
              <td className="px-4 py-3 text-orange-title">
                {t(elements.table.rows.row19[2])}
              </td>
              <td className="px-4 py-3 text-orange-title">
                {t(elements.table.rows.row19[3])}
              </td>
            </tr>
            <tr className=" border-b  ">
              <td className=" text-right px-10 py-10 ">
                {t(elements.table.rows.row20[0])}
              </td>
              <td className="px-4 py-3 text-orange-title">
                {t(elements.table.rows.row20[1])}
              </td>
              <td className="px-4 py-3 text-orange-title">
                {t(elements.table.rows.row20[2])}
              </td>
              <td className="px-4 py-3 text-orange-title">
                {t(elements.table.rows.row20[3])}
              </td>
            </tr>
            <tr className=" border-b  ">
              <td className=" text-right px-10 py-10 ">
                {t(elements.table.rows.row21[0])}
              </td>
              <td className="px-4 py-3 text-orange-title">
                {t(elements.table.rows.row21[1])}
              </td>
              <td className="px-4 py-3 text-orange-title">
                {t(elements.table.rows.row21[2])}
              </td>
              <td className="px-4 py-3 text-orange-title">
                {t(elements.table.rows.row21[3])}
              </td>
            </tr>
            <tr className=" border-b  ">
              <td className=" text-right px-10 py-10 ">
                {t(elements.table.rows.row22[0])}
              </td>
              <td className="px-4 py-3 text-orange-title">
                {t(elements.table.rows.row22[1])}
              </td>
              <td className="px-4 py-3 text-orange-title">
                {t(elements.table.rows.row22[2])}
              </td>
              <td className="px-4 py-3 text-orange-title">
                {t(elements.table.rows.row22[3])}
              </td>
            </tr>
            <tr className=" border-b  ">
              <td className=" text-right px-10 py-10 ">
                {t(elements.table.rows.row23[0])}
              </td>
              <td className="px-4 py-3 text-orange-title">
                {t(elements.table.rows.row23[1])}
              </td>
              <td className="px-4 py-3 text-orange-title">
                {t(elements.table.rows.row23[2])}
              </td>
              <td className="px-4 py-3 text-orange-title">
                {t(elements.table.rows.row23[3])}
              </td>
            </tr>
            <tr className=" border-b  ">
              <td className=" text-right px-10 py-10 ">
                {t(elements.table.rows.row24[0])}
              </td>
              <td className="px-4 py-3 text-orange-title">
                {t(elements.table.rows.row24[1])}
              </td>
              <td className="px-4 py-3 text-orange-title">
                {t(elements.table.rows.row24[2])}
              </td>
              <td className="px-4 py-3 text-orange-title">
                {t(elements.table.rows.row24[3])}
              </td>
            </tr>
            <tr className=" border-b  ">
              <td className=" text-right px-10 py-10 ">
                {t(elements.table.rows.row25[0])}
              </td>
              <td className="px-4 py-3 text-orange-title">
                {t(elements.table.rows.row25[1])}
              </td>
              <td className="px-4 py-3 text-orange-title">
                {t(elements.table.rows.row25[2])}
              </td>
              <td className="px-4 py-3 text-orange-title">
                {t(elements.table.rows.row25[3])}
              </td>
            </tr>
            <tr className=" border-b  ">
              <td className=" text-right px-10 py-10 ">
                {t(elements.table.rows.row26[0])}
              </td>
              <td className="px-4 py-3 text-orange-title">
                {t(elements.table.rows.row26[1])}
              </td>
              <td className="px-4 py-3 text-orange-title">
                {t(elements.table.rows.row26[2])}
              </td>
              <td className="px-4 py-3 text-orange-title">
                {t(elements.table.rows.row26[3])}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="my-8 lg:my-40 ">
        <h1 className=" my-2 text-center  text-4xl font-serif text-gray-700 ">
          {t(elements.levelsDetails.title)}
        </h1>
        <div className=" mx-20 grid grid-cols-3 gap-y-5">
          <h1 className=" text-center m-5 px-10 py-20 text-4xl rounded-3xl bg-button-hover-training ">
            {t(elements.levelsDetails.subtitles[0])}
          </h1>

          <h1 className=" text-center  m-5 px-10 py-20 text-4xl rounded-3xl bg-button-hover-training  ">
            {t(elements.levelsDetails.subtitles[1])}
          </h1>

          <h1 className="text-center  m-5 px-10 py-20 text-4xl rounded-3xl bg-button-hover-training ">
            {t(elements.levelsDetails.subtitles[2])}
          </h1>

          <div>
            <ul className=" list-disc font-sans text-gray-700 mx-20 my-5">
              <li>{t(elements.levelsDetails.descriptions.description1[0])}</li>
              <li>{t(elements.levelsDetails.descriptions.description1[1])}</li>
              <li>{t(elements.levelsDetails.descriptions.description1[2])}</li>
              <li>{t(elements.levelsDetails.descriptions.description1[3])}</li>
            </ul>
          </div>
          <div>
            <ul className=" list-disc font-sans text-gray-700 mx-20 my-5">
              <li>{t(elements.levelsDetails.descriptions.description2[0])}</li>
              <li>{t(elements.levelsDetails.descriptions.description2[1])}</li>
              <li>{t(elements.levelsDetails.descriptions.description2[2])}</li>
              <li>{t(elements.levelsDetails.descriptions.description2[3])}</li>
            </ul>
          </div>
          <div>
            <ul className=" list-disc  font-sans text-gray-700 mx-20 my-5">
              <li>{t(elements.levelsDetails.descriptions.description3[0])}</li>
              <li>{t(elements.levelsDetails.descriptions.description3[1])}</li>
              <li>{t(elements.levelsDetails.descriptions.description3[2])}</li>
              <li>{t(elements.levelsDetails.descriptions.description3[3])}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
