import React, { useEffect, useState } from "react";

const loadDynamicScript = (callback) => {
  const existingScript = document.getElementById('font-awesome');

  if (!existingScript) {
    const css = document.createElement('link');
    css.href = 'https://use.fontawesome.com/releases/v5.15.3/css/all.css';
	  css.rel = 'stylesheet';
	  css.integrity = 'sha384-SZXxX4whJ79/gErwcOYf+zWLeJdY/qpuqC4cAa9rOGUstPomtqpuNWT9wdPEn2fk';
    css.crossOrigin = 'anonymous';
    css.id = 'font-awesome';
    document.body.appendChild(css);

    css.onload = () => {
      if (callback) callback();
    };
  }

  if (existingScript && callback) callback();
};

export const FontAwesome = () => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    loadDynamicScript(() => {
        setLoaded(true);
    });
  });

  return (
    <div id='font-awesome-element'>
        {loaded ? '':''}
    </div>
  );
};

export default FontAwesome;