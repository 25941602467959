import React from "react";
import { useTranslation } from "react-i18next";

export const LanguageSwitcher = ( props ) => {
  const { i18n, t } = useTranslation();

  const onClcik = (e) => {
    i18n.changeLanguage(e.target.id);
    props.handler();
  };

  return (
    <>
      <ul className="absolute flex flex-col mt-10 bg-blue items-center w-40">
        {props.menuItems.map((item, index) => {
            return (
                <li key={item.id} id={item.id} 
                    onClick={onClcik}
                    className="flex text-white hover:bg-blue-light-less w-full p-4">
                        {t(item.title)}
                </li>
            )
        })}
      </ul>
    </>
  )
};