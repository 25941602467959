import React from "react";
import loadable from "@loadable/component";

import { useTranslation } from "react-i18next";
import { Settings } from "../app.config";
import { Link } from "react-router-dom";

const Video = loadable(() => import("../components/commons/Video"));

export const Certification = () => {
  const elements = Settings.pages.certification;
  const { t } = useTranslation();
  return (
    <div className="flex flex-col items-center">
      <div className="flex flex-col items-center">
        <h1 className="text-7xl text-gray-800 py-10 ">
          {t(elements.header.title[0])} <br />
          {t(elements.header.title[1])}
        </h1>
        <div className="py-6 flex flex-col items-center">
          <div className="w-2/4 text-center flex flex-col items-center">
            <h1 className="text-5xl text-gray-800 py-10 ">
              {t(elements.blocks.certification.title[0])} <br />
              {t(elements.blocks.certification.title[1])}
            </h1>
            <h3 className="text-center mx-10 text-2xl font-serif  text-orange-title">
              {t(elements.blocks.certification.subtitle[0])} <br />
              {t(elements.blocks.certification.subtitle[1])}
            </h3>

            <p className="mx-10 text-lg my-10">
              {t(elements.blocks.certification.explanation)}
            </p>
            <img
              alt="img"
              className="my-5"
              src="https://www.coachtrainingedu.com/wp-content/uploads/2020/12/logo-all-black-and-white.png"
            />
            <Video videos={elements.videos} />
          </div>
          <div className="py-3 flex flex-col items-center">
            <div className="text-center flex flex-col items-center">
              <h1 className="text-5xl text-gray-800 py-10 ">
                {t(elements.blocks.info.title[0])} <br />
                {t(elements.blocks.info.title[1])}
              </h1>
              <h3 className="mx-10 text-2xl font-serif  text-orange-title">
                {t(elements.blocks.info.subtitle[0])} <br />
                {t(elements.blocks.info.subtitle[1])}
              </h3>
              <h3 className=" text-gray-600 text-xl my-4 w-1/2">
                {t(elements.blocks.info.explanation)}
              </h3>
              <p className="mx-10 text-gray-600 text-lg my-4">
                {elements.blocks.info.items.map((el, index) => {
                  return (
                    <div key={index} className="py-2">
                      {t(el)}
                    </div>
                  );
                })}
              </p>
              <Link
                className="bg-green-900 text-white py-1 px-7 rounded-full uppercase"
                to="/"
              >
                Speak to an Advisor <i className="fas fa-rocket"></i>
              </Link>
            </div>
          </div>
          <div className="flex flex-col items-center text-center font-serif bg-yellow-100 py-10 my-3">
            <h1 className="text-4xl font-serif text-gray-700 w-3/5 py-4">
              {t(elements.blocks.programs.title[0])} <br />
              {t(elements.blocks.programs.title[1])}
            </h1>
            <div className="flex">
              <div className="w-1/3 mx-5 flex flex-col items-stretch py-4">
                <h2 className="text-4xl text-gray-700">
                  {t(elements.blocks.programs.subtitle[0])}
                </h2>
                <p className="m-5">
                  {t(elements.blocks.programs.explanation[0])} <br />
                  {elements.blocks.programs.item1.map((el, index) => {
                    return (
                      <div
                        key={index}
                        className="text-left py-2 flex justify-center items-center"
                      >
                        <i className=" mx-5 fas fa-bookmark"></i> {t(el)}
                      </div>
                    );
                  })}
                </p>
              </div>
              <div className="w-1/3 mx-5 flex flex-col items-stretch py-4">
                <h2 className="text-4xl text-gray-700">
                  {t(elements.blocks.programs.subtitle[1])}
                </h2>
                <p className="m-5">
                  {t(elements.blocks.programs.explanation[1])} <br />
                  {elements.blocks.programs.item2.map((el, index) => {
                    return (
                      <div
                        key={index}
                        className="text-left py-2 flex justify-center items-center"
                      >
                        <i className=" mx-5 fas fa-bookmark"></i> {t(el)}
                      </div>
                    );
                  })}
                </p>
              </div>
              <div className="w-1/3 mx-5 flex flex-col items-stretch py-4">
                <h2 className="text-4xl text-gray-700">
                  {t(elements.blocks.programs.subtitle[2])}
                </h2>
                <p className="m-5">
                  {t(elements.blocks.programs.explanation[2])} <br />
                  {elements.blocks.programs.item3.map((el, index) => {
                    return (
                      <div
                        key={index}
                        className="text-left py-2 flex justify-center items-center"
                      >
                        <i className=" mx-5 fas fa-bookmark"></i> {t(el)}
                      </div>
                    );
                  })}
                </p>
              </div>
            </div>
            <Link
              className="bg-green-900 text-white py-1 px-7 rounded-full uppercase"
              to="/"
            >
              See Program Pricing
            </Link>
          </div>
        </div>
        <div className="flex flex-col items-center py-10">
          <img
            alt="png"
            src="https://www.coachtrainingedu.com/wp-content/uploads/elementor/thumbs/image-7-p4flwiws703ic0nsjbvm8i9zms72gjpz6lxpgzmh0w.png?189db0&189db0"
          />
          <div className="flex flex-col items-center">
            <div className=" mx-auto w-3/5 text-center my-8 ">
              <h1 className=" mx-10 text-4xl font-serif text-gray-900">
                {t(elements.blocks.trial.title)}
              </h1>
              <h1 className="my-10 text-2xl text-orange-title  ">
                {t(elements.blocks.trial.subtitle)}
              </h1>
              <h2 className="my-5 font-sans-serif text-gray-900">
                {t(elements.blocks.trial.explanation)}
              </h2>
            </div>
            <div className="w-2/5 text-gray-900">
              <div className="grid grid-flow-col grid-cols-2 grid-rows-2 gap-6 ">
                {elements.blocks.trial.items.map((el, index) => {
                  return (
                    <div key={index} className="py-2 flex justify-center">
                      ✓ {t(el)}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col items-center">
          <h1 className="text-4xl text-gray-800 py-10 ">
            {t(elements.blocks.process.title)}
          </h1>
          <div class="max-w-md mx-auto">
            <div class="flex flex-no-wrap lg:flex-row flex-row-reverse justify-center pt-10">
              <div class="w-2/5 sm:2/3 flex-none p-2">
                <div class="text-gray-700 lg:float-right float-left rounded bg-yellow-600 shadow-lg px-2">
                  <p className="text-white p-2 w-96 h-52 flex justify-center items-center">
                    {" "}
                    {t(elements.blocks.process.explanation[0])}
                  </p>
                </div>
              </div>
              <div class="w-1/5 sm:1/3 flex-none float-left">
                <div class="bg-yellow-600 p-1 h-full w-1 mx-auto">
                  <button class="h-24 w-24 mt-56 bg-yellow-600 hover:bg-yellow-700 text-white font-bold rounded-full -ml-12">
                    {t(elements.blocks.process.steps[0])}
                  </button>
                </div>
              </div>
              <div class="w-2/5 flex-none p-2 hidden lg:block"></div>
            </div>

            <div class="flex flex-no-wrap sm:flex-row-reverse1 justify-center">
              <div class="w-2/5 sm:2/3 flex-none p-2 hidden lg:block"></div>
              <div class="w-1/5 sm:1/3 flex-none float-left">
                <div class="bg-yellow-600 p-1 h-full w-1 mx-auto">
                  <button class="h-24 w-24 mt-56 bg-yellow-600 hover:bg-yellow-700 text-white font-bold rounded-full -ml-12">
                    {t(elements.blocks.process.steps[1])}
                  </button>
                </div>
              </div>
              <div class="w-2/5 flex-none p-2">
                <div class="text-gray-700  float-left rounded bg-yellow-600 shadow-lg px-2">
                  <p className="text-white p-2 w-96 h-52 flex justify-center items-center">
                    {" "}
                    {t(elements.blocks.process.explanation[1])}
                  </p>
                </div>
              </div>
            </div>
            <div class="flex flex-no-wrap lg:flex-row flex-row-reverse justify-center">
              <div class="w-2/5 sm:2/3 flex-none p-2">
                <div class="text-gray-700 lg:float-right float-left rounded bg-yellow-600 shadow-lg px-2">
                  <p className="text-white p-2 w-96 h-52 flex justify-center items-center">
                    {" "}
                    {t(elements.blocks.process.explanation[2])}
                  </p>
                </div>
              </div>
              <div class="w-1/5 sm:1/3 flex-none float-left">
                <div class="bg-yellow-600 p-1 h-full w-1 mx-auto">
                  <button class="h-24 w-24 mt-56 bg-yellow-600 hover:bg-yellow-700 text-white font-bold rounded-full -ml-12">
                    {t(elements.blocks.process.steps[2])}
                  </button>
                </div>
              </div>
              <div class="w-2/5 flex-none p-2 hidden lg:block"></div>
            </div>

            <div class="flex flex-no-wrap sm:flex-row-reverse1 justify-center">
              <div class="w-2/5 sm:2/3 flex-none p-2 hidden lg:block"></div>
              <div class="w-1/5 sm:1/3 flex-none float-left">
                <div class="bg-yellow-600 p-1 h-full w-1 mx-auto">
                  <button class="h-24 w-24 mt-56 bg-yellow-600 hover:bg-yellow-700 text-white font-bold rounded-full -ml-12">
                    {t(elements.blocks.process.steps[3])}
                  </button>
                </div>
              </div>
              <div class="w-2/5 flex-none p-2">
                <div class="text-gray-700 float-left rounded bg-yellow-600 shadow-lg px-2">
                  <p className="text-white p-2 w-96 h-52 flex justify-center items-center">
                    {" "}
                    {t(elements.blocks.process.explanation[3])}
                  </p>
                </div>
              </div>
            </div>

            <div class="flex flex-no-wrap lg:flex-row flex-row-reverse justify-center">
              <div class="w-2/5 sm:2/3 flex-none p-2">
                <div class="text-gray-700 lg:float-right float-left rounded bg-yellow-600 shadow-lg px-2">
                  <p className="text-white p-2 w-96 h-52 flex justify-center items-center">
                    {" "}
                    {t(elements.blocks.process.explanation[4])}
                  </p>
                </div>
              </div>
              <div class="w-1/5 sm:1/3 flex-none float-left">
                <div class="bg-yellow-600 p-1 h-full w-1 mx-auto">
                  <button class="h-24 w-24 mt-56 bg-yellow-600 hover:bg-yellow-700 text-white font-bold rounded-full -ml-12">
                    {t(elements.blocks.process.steps[4])}
                  </button>
                </div>
              </div>
              <div class="w-2/5 flex-none p-2 hidden lg:block"></div>
            </div>
          </div>
          <h1>{t(elements.blocks.advcourses.explanation[6])}</h1>
          <br />
          <h1>{t(elements.blocks.advcourses.explanation[7])}</h1>
        </div>
        <div className="flex flex-col items-center text-center font-serif py-10 my-3">
          <h1 className="w-3/5 mx-10 text-4xl font-serif text-gray-900">
            {t(elements.blocks.advcourses.title[0])} <br />
            {t(elements.blocks.advcourses.title[1])}
          </h1>
          <div className="flex my-6">
            <div className="w-1/2 mx-5 flex flex-col items-center py-4">
              <h2 className="text-3xl text-gray-700">
                {t(elements.blocks.advcourses.subtitle[0])}
              </h2>
              <p className="m-5">
                {t(elements.blocks.advcourses.explanation[0])} <br />
              </p>
              <Link
                className="bg-green-900 text-white py-1 px-7 rounded-full uppercase w-1/2"
                to="/"
              >
                Learn More
              </Link>
            </div>
            <div className="w-1/2 mx-5 flex flex-col items-center py-4">
              <h2 className="text-3xl text-gray-700">
                {t(elements.blocks.advcourses.subtitle[1])}
              </h2>
              <p className="m-5">
                {t(elements.blocks.advcourses.explanation[1])} <br />
              </p>
              <Link
                className="bg-green-900 text-white py-1 px-7 rounded-full uppercase w-1/2"
                to="/"
              >
                Learn More
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
