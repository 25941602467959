import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const loadDynamicScript = (callback) => {
  const existingScript = document.getElementById("swarmify");

  if (!existingScript) {
    const script = document.createElement("script");
    script.src = "https://assets.swarmcdn.com/cross/swarmdetect.js";
    script.id = "swarmify";
    document.body.appendChild(script);

    script.onload = () => {
      if (callback) callback();
    };
  }

  if (existingScript && callback) callback();
};

export const AcademicBlock = ({ video, data, reverse }) => {
  const { t } = useTranslation();

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    loadDynamicScript(() => {
      setLoaded(true);
    });
  });

  return (
    <div
      className={`container mx-auto w-3/4 flex ${
        reverse ? "flex-row-reverse" : ""
      }`}
    >
      <div>
        <div id="videos">
          {loaded ? (
            <div className="py-20">
              <smartvideo src={video} controls></smartvideo>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className=" container mx-auto px-10 my-8 lg:my-20  ">
        {data.title.length > 0 && (
          <h1 className="text-3xl text-center  font-serif text-gray-900">
            {t(data.title[0])}
            {data.title.length > 1 && (
              <>
                <br /> {t(data.title[1])}
              </>
            )}
          </h1>
        )}
        <h2 className="my-10  text-justify text-2l text-gray-900">
          {t(data.explanation)}
        </h2>

        <div className="gap-6 text-gray-900">
          {data.items.map((el, index) => {
            return <div key={index}> ✓ {t(el)}</div>;
          })}
        </div>
      </div>
    </div>
  );
};
